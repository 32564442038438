/* eslint-disable import/no-cycle */
import HOME from '../../resources/Home/home.resource';
import RESOURCES from '../../utils/RESOURCES';
import { useStyles } from './styles/informationStyles';
import { Link } from 'react-router-dom';
import ROUTES from 'routes/routes';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';

const Information = ({ styleMapper, location, enableCancelLink }) => {
  const HELP_ITEMS = HOME.INFORMATION.HELP_ITEMS[1].CONTENT_ITEM;
  const styles = useStyles(styleMapper, location);
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();

  const handleClickCancelLink = () => {
    dispatchAnalyticsEvent('HOME', 'A10');
  };

  return (
    <div className={styles.information}>
      <div className={styles.doubts}>
        <div className={styles.footerTopic}>
          <h2>{RESOURCES.FOOTER.HELP_ITEM[0].TITLE}</h2>
          <p className={styles.description}>{RESOURCES.FOOTER.HELP_ITEM[0].TIME}</p>
        </div>
        <div className={styles.footerItems}>
          <div className={styles.footerItemContent}>
            <div>
              <h3>{RESOURCES.FOOTER.HELP_ITEM[0].CONTENT[0].TITLE}</h3>
              <p className={styles.description}>{RESOURCES.FOOTER.HELP_ITEM[0].CONTENT[0].TEL}</p>
            </div>
          </div>
          <div className={styles.footerItemContent} style={{ justifyContent: 'center' }}>
            <div>
              <h3>{RESOURCES.FOOTER.HELP_ITEM[0].CONTENT[1].TITLE}</h3>
              <p className={styles.description}>{RESOURCES.FOOTER.HELP_ITEM[0].CONTENT[1].TEL}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.central}>
        <div className={styles.footerTopic}>
          <h2 id="contacts-wrapper">{RESOURCES.FOOTER.HELP_ITEM[1].TITLE}</h2>
        </div>
        <div className={styles.footerItems}>
          {HELP_ITEMS.map((item, i) => (
            <div
              className={styles.footerItemContent}
              key={i}
              style={{
                justifyContent: i !== 0 && i !== 3 ? 'center' : undefined,
              }}
            >
              <div>
                <h3>{item.TITLE}</h3>
                <p>{item.TEL}</p>
                <p>{item.TIME}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {enableCancelLink && <div className={styles.cancellation}>
        <div className={styles.footerTopic}>
          <h2>{RESOURCES.FOOTER.HELP_ITEM[2].TITLE}</h2>
          <p className={styles.description}>{RESOURCES.FOOTER.HELP_ITEM[2].TIME}</p>
        </div>
        <div>
          <h3>{RESOURCES.FOOTER.HELP_ITEM[2].CONTENT[0].TITLE}</h3>
          <p className={styles.description}>
            <Link to={`${ROUTES.AUTH_V2}/entrar`} onClick={handleClickCancelLink}>
              {RESOURCES.FOOTER.HELP_ITEM[2].CONTENT[0].LINK}
            </Link>
          </p>
        </div>
      </div>}
    </div>
  );
};

export default Information;
