import ROUTES from 'routes/routes';
import config from '../../../config';
import { ANALYTICS_MENU_FLOW } from '../../../tracking';

const { ADVISORY } = config;

const NEW_HELP = {
  DESKTOP_ITEMS: [
    // perguntas frequentes
    {
      title: 'perguntas frequentes',
      id: 'perguntasFrequentesDesktop',
      items: [
        {
          text: 'seguro auto',
          link: `${ROUTES.FAQ_PAGE}?productType=Auto`,
          id: ANALYTICS_MENU_FLOW.MENU_AJUDA_AUTO,
        },
        {
          text: 'seguro residencial',
          link: `${ROUTES.FAQ_PAGE}?productType=Residencial`,
          id: ANALYTICS_MENU_FLOW.MENU_AJUDA_RESID,
        },
        {
          text: 'plano odontológico',
          link: `${ROUTES.FAQ_PAGE}?productType=Odonto`,
          id: ANALYTICS_MENU_FLOW.MENU_AJUDA_ODONTO,
        },
      ],
    },
    // vendas e dúvidas
    {
      title: 'vendas e dúvidas',
      id: 'vendasDuvidasDesktop',
      items: [
        {
          text: 'capitais e regiões metropolitanas \n4020 2031',
          id: 'vendasCapitaisRegioesDesktop',
        },
        {
          text: 'demais regiões   0800 545 1150',
          id: 'vendasDemaisRegioesDesktop',
        },
      ],
      officeHours: '2ª a 6ª das 9h às 18h',
    },
    // itaú corretora de seguros
    {
      title: 'itaú corretora de seguros',
      id: 'itauCorretoraSegurosDesktop',
      items: [
        {
          text: 'capitais e regiões metropolitanas\n4090 1125',
          id: 'itauCapitaisRegioesDesktop',
        },
        {
          text: 'demais regiões   0800 791 1125',
          id: 'itauDemaisRegioesDesktop',
        },
        {
          text: 'SAC   0800 728 0728',
          id: 'itauSacDesktop',
        },
        {
          text: 'ouvidoria   0800 570 0011',
          id: 'itauOuvidoriaDesktop',
        },
        {
          text: 'deficiente auditivo/fala\n0800 722 1722 ',
          id: 'itauDeficienteAuditivoDesktop',
        },
      ],
      officeHours: '2ª a 6ª das 8h às 20h ',
    },
  ],
  MOBILE_ITEMS: [
    // vendas e dúvidas
    {
      title: 'vendas e dúvidas',
      id: 'vendasDuvidasMobile',
      items: [
        {
          text: 'capitais e regiões metropolitanas \n4020 2031',
          id: 'vendasCapitaisRegioesMobile',
        },
        {
          text: 'demais regiões   0800 545 1150',
          id: 'vendasDemaisRegioesMobile',
        },
      ],
      officeHours: '2ª a 6ª das 9h às 18h',
    },
    // itaú corretora de seguros
    {
      title: 'itaú corretora de seguros',
      id: 'itauCorretoraSegurosMobile',
      items: [
        {
          text: 'capitais e regiões metropolitanas \n4090 1125',
          id: 'itauCapitaisRegioesMobile',
        },
        {
          text: 'demais regiões   0800 791 1125',
          id: 'itauDemaisRegioesMobile',
        },
        {
          text: 'SAC   0800 728 0728',
          id: 'itauSacMobile',
        },
        {
          text: 'ouvidoria   0800 570 0011',
          id: 'itauOuvidoriaMobile',
        },
        {
          text: 'deficiente auditivo/fala   0800 722 1722 ',
          id: 'itauDeficienteAuditivoMobile',
        },
      ],
      officeHours: '2ª a 6ª das 8h às 20h ',
    },
  ],
};

if (ADVISORY.enabled) {
  const advisoryItem = {
    text: 'como escolher um seguro?',
    link: ROUTES.ADVISORY_RESEARCH,
    id: 'assessoriaSeguros',
  };
  NEW_HELP.DESKTOP_ITEMS[0].items.unshift(advisoryItem);
}


export default NEW_HELP;
