/* eslint-disable react/prop-types */
import parse from 'html-react-parser';
import { Link, useLocation } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import { useProjectContextProvider } from 'pages/Welcome/providers/ProjectContextProvider';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import RESOURCES from '../../utils/RESOURCES';
import ROUTES from 'routes/routes';
import AriaReader from '../../components/AriaReader/AriaReader';
import Information from '../../components/Information/Information';
import { TERMS_URL } from '../../resources/Texts/Odonto/Terms';
import Dropdown from '../../components/Dropdown/Dropdown';
import { useStyles } from './styles/footerStyles';
import { hasStyleMapper } from '../../pages/Welcome/utils/util';
import insuranceLogo from '../../assets/images/logo-seguros-itau-branco.png';
import { useRemoteConfigConsumer } from "../../providers/RemoteConfigProvider";

const DesktopFooter = ({ styles, styleMapper, location, onClickTermsOfUseLink, enableCancelLink }) => (
  <>
    <Information styleMapper={styleMapper} location={location} enableCancelLink={enableCancelLink}/>
    <div className="container">
      <div className={`${styles.wrapper} ${styles.info}`}>
        <div></div>
        <div>
          <span className={styles.registry}>{RESOURCES.FOOTER.REGISTRY}</span>
          <div className={styles.location}>{RESOURCES.FOOTER.LOCATION}</div>
          <p>versão: {process.env.REACT_APP_VERSION}</p>
        </div>
        <p>
          <a href={TERMS_URL} onClick={onClickTermsOfUseLink} target="_blank" rel="noopener noreferrer">
            termos de uso
          </a>
          <AriaReader reader="Final da página" />
        </p>
      </div>
    </div>
  </>
);

const MobileFooter = ({ styles, image, onClickCancelLink, onClickTermsOfUseLink, enableCancelLink }) => (
  <div className={`${styles.wrapper} ${styles.info}`}>
    <img
      src={image}
      className={styles.logoLink}
      alt="Seguros Itaú"
      id="lnkLogo_componentFooter"
      width="102"
      height="32"
    />
    <Dropdown
      classNameRoot={styles.dropDown}
      context="footer"
      page="home_sale"
      id={0}
      content={
        <>
          {RESOURCES.FOOTER.HELP_ITEM[0].CONTENT.map((item) => (
            <div key={item.TITLE}>
              <h3>{item.TITLE}</h3>
              <p> {item.TEL}</p>
              <p> {item.TIME}</p>
            </div>
          ))}
        </>
      }
      title={RESOURCES.FOOTER.HELP_ITEM[0].TITLE}
    />
    <Dropdown
      classNameRoot={styles.dropDown}
      content={
        <>
          {RESOURCES.FOOTER.HELP_ITEM[1].CONTENT.map((item) => (
            <div key={item.TITLE}>
              <h3>{item.TITLE}</h3>
              <p> {item.TEL}</p>
              <p> {item.TIME}</p>
            </div>
          ))}
        </>
      }
      title={RESOURCES.FOOTER.HELP_ITEM[1].TITLE}
    />
    {enableCancelLink &&
      <Dropdown
        classNameRoot={styles.dropDown}
        context="footer"
        page="home_cancel"
        id={0}
        content={
          <>
            <h3>{RESOURCES.FOOTER.HELP_ITEM[2].CONTENT[0].TITLE}</h3>
            <p className="description">
              <Link to={`${ROUTES.AUTH_V2}/entrar`} onClick={onClickCancelLink}>
                {RESOURCES.FOOTER.HELP_ITEM[2].CONTENT[0].LINK}
              </Link>
            </p>
          </>
        }
        title={RESOURCES.FOOTER.HELP_ITEM[2].TITLE}
      />
    }
    <div>
      <span className={styles.mobileRegistry}>{parse(RESOURCES.FOOTER.MOBILE_REGISTRY)}</span>
      <div className={styles.mobileLocation}>{parse(RESOURCES.FOOTER.MOBILE_LOCATION)}</div>
    </div>
    <p>
      <a href={TERMS_URL} onClick={onClickTermsOfUseLink} target="_blank" rel="noopener noreferrer">
        termos de uso
      </a>
    </p>
    <p>versão: {process.env.REACT_APP_VERSION}</p>
    <AriaReader reader="Final da página" />
  </div>
);

const Footer = () => {
  const remoteConfig = useRemoteConfigConsumer();
  const { ENABLE_CANCEL_FLOW_FOOTER } = remoteConfig;
  const { styleMapper } = useProjectContextProvider();
  const location = useLocation();
  const styles = useStyles(styleMapper, location);
  const handleChangeImage = hasStyleMapper(styleMapper, location) ? styleMapper.footer.image : insuranceLogo;
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();

  const handleClickCancelLink = () => {
    dispatchAnalyticsEvent('HOME', 'A10');
  };

  const handleClickTermsOfUseLink = () => {
    dispatchAnalyticsEvent('HOME', 'A11');
  };

  return (
    <footer className={`footer ${styles.footer}`}>
      <Hidden smDown>
        <DesktopFooter
          styles={styles}
          styleMapper={styleMapper}
          image={handleChangeImage}
          location={location}
          onClickTermsOfUseLink={handleClickTermsOfUseLink}
          enableCancelLink={ENABLE_CANCEL_FLOW_FOOTER}
        />
      </Hidden>
      <Hidden mdUp>
        <MobileFooter
          styles={styles}
          image={handleChangeImage}
          onClickCancelLink={handleClickCancelLink}
          onClickTermsOfUseLink={handleClickTermsOfUseLink}
          enableCancelLink={ENABLE_CANCEL_FLOW_FOOTER}
        />
      </Hidden>
    </footer>
  );
};

export default Footer;
