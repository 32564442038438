/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import config from 'config';
import { createBrowserHistory } from 'history';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import RecaptchaProvider from 'providers/RecaptchaProvider';
import ROUTES from 'routes/routes';
import { useRemoteConfigConsumer } from 'providers/RemoteConfigProvider';
import { getPureChannel } from 'utils/channels';
import loadable from '@loadable/component';
import { PrerenderedComponent } from 'react-prerendered-component';
import { Loading } from '../components';
import { AllowedChannelsContext } from '../providers/AllowedChannelsProvider';
import { getPageContent } from '../api/CMS/getHomeContent';
import { ENVIRONMENTS } from '../cms/constants/environments';
import validateAuthentication from 'utils/authentication';

const prerenderedLoadable = (dynamicImport) => {
  const LoadableComponent = loadable(dynamicImport);
  return React.memo((props) => (
    <PrerenderedComponent live={LoadableComponent.load()}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ));
};

const AutoV2 = React.lazy(() => import('../pages/AutoV2/AutoV2'));
const AuthV2Base = React.lazy(() => import('../pages/AuthV2/AuthV2Base'));
const MyAccountBase = React.lazy(() => import('../pages/MyAccount/MyAccountBase'));
const MyProductsBase = React.lazy(() => import('../pages/MyProducts/MyProductsBase'));
const Home = prerenderedLoadable(() => import('../pages/Home'));
const FAQV2 = React.lazy(() => import('../pages/FAQV2/FAQV2'));
const LandingOdonto = prerenderedLoadable(() => import('../pages/Landings/Odonto/Odonto'));
const LandingAuto = prerenderedLoadable(() => import('../pages/Landings/Auto/AutoWrapper/AutoWrapper'));
const LandingResid = prerenderedLoadable(() => import('../pages/Landings/Resid/ResidWrapper/ResidWrapper'));
const LandingWelfare = prerenderedLoadable(() => import('../pages/Landings/Realiza/Welfare/WelfareWrapper'));
const LandingPet = prerenderedLoadable(() => import('../pages/Landings/Realiza/Pet/Pet'));
const LandingCarglass = React.lazy(() => import('../pages/Landings/Carglass/CarglassContentWrapper'));
const LandingBllu = React.lazy(() => import('../pages/Landings/Bllu/BlluContentWrapper'));
const LandingConectcar = React.lazy(() => import('../pages/Landings/Conectcar/ConectcarContentWrapper'));
const LandingConsorcio = React.lazy(() => import('../pages/Landings/Consorcio/ConsorcioContentWrapper'));
const FaqPage = React.lazy(() => import('../pages/Landings/FaqPage/FaqPage'));
const IconsPage = React.lazy(() => import('../pages/IconsPage/IconsPage'));
const ErrorPage = React.lazy(() => import('../pages/ErrorPage/ErrorPage'));
const CancellationPage = React.lazy(() => import('../pages/Cancellation'));
const ReceiptPage = React.lazy(() => import('../pages/ReceiptPage/ReceiptPage'));
const ResidReceiptPage = React.lazy(() => import('../pages/ReceiptPage/ResidReceiptPage'));
const OdontoHiringPage = React.lazy(() => import('../pages/Landings/Odonto/OdontoHiring/OdontoHiringPage'));
const ResidHiringPage = React.lazy(() => import('../pages/Landings/Resid/ResidHiring/ResidHiringPage'));
const WelfareHiringPage = React.lazy(() => import('../pages/Landings/Realiza/Welfare/Hiring/WelfareHiringPage'));
const PetHiringPage = React.lazy(() => import('../pages/Landings/Realiza/Pet/Hiring/PetHiringPage'));
const Blog = React.lazy(() => import('../pages/Blog/blog'));

const PetSuccessPage = React.lazy(() =>
  import('../pages/Landings/Realiza/Pet/Hiring/ReceiptStep/PetReceiptStepContainer')
);
const CancellationDetailPage = React.lazy(() => import('../pages/Cancellation/detail/CancellationDetail'));
const CancellationReceiptPage = React.lazy(() => import('../pages/Cancellation/receipt/CancellationReceipt'));
const CancellationTokenConfirmationPage = React.lazy(() =>
  import('../pages/Cancellation/token-confirmation/CancellationTokenConfirmation')
);

const DownloadReceiptPage = React.lazy(() => import('../pages/DownloadReceiptPage'));
const RealizaPetTermsPage = React.lazy(() => import('../pages/Terms/Realiza/Pet'));
const RealizaWelfareTermsPage = React.lazy(() => import('../pages/Terms/Realiza/Welfare'));
const AuthenticationSecondStep = React.lazy(() =>
  import('../pages/Authentication/AuthenticationSecondStep/AuthenticationSecondStep')
);
const RedirectToAppOrWebsitePage = React.lazy(() => import('../pages/RedirectToAppOrWebsite/RedirectToAppOrWebsite'));

const AdvisoryPage = React.lazy(() => import('../sections/Advisory'));

const ValidateUserDataToAsyncQuotationFlowPage = React.lazy(() =>
  import('pages/Landings/Resid/ValidateUserDataToAsyncQuotationFlowPage/ValidateUserDataToAsyncQuotationFlowPage')
);

const AsyncQuotationExpiredErrorPage = React.lazy(() =>
  import('pages/Landings/Resid/AsyncQuotationExpiredErrorPage/AsyncQuotationExpiredErrorPage')
);
const DynamicLandings = React.lazy(() => import('../pages/DynamicLandings/DynamicLandings'));

const { REACT_APP_ENVIRONMENT } = process.env;

const isNotProduction = REACT_APP_ENVIRONMENT !== 'production' && REACT_APP_ENVIRONMENT !== 'internal';

export const history = createBrowserHistory();

const ProtectedRoute = ({ canActivate, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      canActivate ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: ROUTES.HOME, state: { from: props.location } }} />
      )
    }
  />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState();

  useEffect(() => {
    async function authenticated() {
      const isValid = await validateAuthentication();
      setAuth(isValid);
    }

    authenticated();
  }, []);

  useEffect(() => {
    if (auth === undefined) return;

    auth === true ? setLoading(false) : window.location.replace(ROUTES.HOME);
  }, [auth]);

  return !loading ? <Route {...rest} render={(props) => <Component {...props} />} /> : <Loading />;
};

export default function Content({ partner, userHasQuotation, loading }) {
  const { ADVISORY, ENABLE_AUTO_EVOLUTION, DYNAMIC_LANDPAGE } = config;
  const { search } = useLocation();
  const remoteConfig = useRemoteConfigConsumer();

  const { AUTO_V2_ENABLED_CHANNELS, ENABLE_AUTH, ENABLE_FAQ_V2, ENABLE_MY_ACCOUNT } = remoteConfig;
  const enabledAutoV2ByChannelRule = AUTO_V2_ENABLED_CHANNELS?.includes(getPureChannel());
  const env = ENVIRONMENTS[REACT_APP_ENVIRONMENT];
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    async function fetchRoutesCSMAPI() {
      try {
        const res = await getPageContent('seg_dynamiclandpage', env);
        setRoutes(res.entries);
      } catch (err) {
        return [];
      }
    }
    fetchRoutesCSMAPI();
  }, []);

  if (loading) return null;

  return (
    <React.Suspense fallback={<Loading />}>
      <Switch history={history}>
        {DYNAMIC_LANDPAGE && routes.map((route) => <Route exact path={route.url} component={DynamicLandings} />)}
        <Redirect exact from="/residencial" to={{ pathname: ROUTES.LANDING_RESID, search }} />
        <Redirect exact from="/residencial/contratacao" to={{ pathname: ROUTES.RESID_HIRING_PAGE, search }} />
        <Redirect
          exact
          from="/residencial/contratacao/comprovante"
          to={{ pathname: ROUTES.RESID_HIRING_SUCCESS_PAGE, search }}
        />
        <Redirect exact from="/auto" to={{ pathname: ROUTES.LANDING_AUTO, search }} />
        <Redirect exact from="/auto/contratacao" to={{ pathname: ROUTES.HIRING_PAGE, search }} />
        <Redirect exact from="/auto/contratacao/vistoria" to={{ pathname: ROUTES.INSPECTION_PAGE, search }} />
        <Redirect exact from="/auto/cotacao/sucesso" to={{ pathname: ROUTES.SUCCESS_LEAD_SIMULATION, search }} />
        <Redirect exact from="/auto/contratacao/comprovante" to={{ pathname: ROUTES.AUTO_SUCCESS_PAGE, search }} />
        <Redirect exact from="/auto/contratacao/vistoria/aplicativo" to={{ pathname: ROUTES.INSPECTION_APP, search }} />
        <Redirect
          exact
          from="/auto/contratacao/vistoria/dispensado"
          to={{ pathname: ROUTES.INSPECTION_DISPENSED, search }}
        />
        <Redirect
          exact
          from="/auto/contratacao/vistoria/agendado"
          to={{ pathname: ROUTES.INSPECTION_SCHEDULED, search }}
        />
        <Redirect
          exact
          from="/auto/contratacao/vistoria/sucesso"
          to={{ pathname: ROUTES.INSPECTION_SUCCESS, search }}
        />
        <Redirect exact from="/auto/contratacao/erro" to={{ pathname: ROUTES.NOT_FOUND_CAR_MODEL, search }} />
        <Redirect exact from="/odonto" to={{ pathname: ROUTES.LANDING_ODONTO, search }} />
        <Redirect exact from="/odonto/contratacao" to={{ pathname: ROUTES.ODONTO_HIRING_PAGE, search }} />
        <Redirect
          exact
          from="/odonto/contratacao/comprovante"
          to={{ pathname: ROUTES.ODONTO_HIRING_SUCCESS_PAGE, search }}
        />
        <Redirect exact from="/faq" to={{ pathname: ROUTES.FAQ_PAGE, search }} />
        <Redirect exact from="/realiza-pet" to={{ pathname: ROUTES.LANDING_PET, search }} />
        <Redirect exact from="/realiza-pet/contratacao" to={{ pathname: ROUTES.PET_HIRING_PAGE, search }} />
        <Redirect
          exact
          from="/realiza-pet/contratacao/comprovante"
          to={{ pathname: ROUTES.PET_SUCCESS_PAGE, search }}
        />
        <Redirect exact from="/realiza-pet/manual" to={{ pathname: ROUTES.REALIZA_PET_TERMS, search }} />
        <Redirect exact from="/seguro-auto" to={{ pathname: ROUTES.AUTO_V2_HOME, search }} />
        <Redirect exact from="/realiza-saude" to={{ pathname: ROUTES.LANDING_WELFARE, search }} />
        <Redirect exact from="/realiza-saude/contratacao" to={{ pathname: ROUTES.WELFARE_HIRING_PAGE, search }} />
        <Redirect
          exact
          from="/realiza-saude/contratacao/comprovante"
          to={{ pathname: ROUTES.WELFARE_SUCCESS_PAGE, search }}
        />
        <Redirect exact from="/realiza-saude/manual" to={{ pathname: ROUTES.REALIZA_WELFARE_TERMS, search }} />
        <Route path={ROUTES.LANDING_AUTO_V1} render={() => <LandingAuto partner={partner.data?.auto} />} />
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route exact path={ROUTES.HOME} component={() => <Home />} />
        <Route exact path={ROUTES.ERROR_PAGE} component={ErrorPage} />
        <Route exact path={ROUTES.LANDING_ODONTO} render={() => <LandingOdonto partner={partner.data.odonto} />} />
        <Route exact path={ROUTES.LANDING_RESID} render={() => <LandingResid partner={partner.data.residencial} />} />
        <Route exact path={ROUTES.FAQ_PAGE} component={FaqPage} />
        <Route exact path={ROUTES.ODONTO_HIRING_SUCCESS_PAGE} component={() => <ReceiptPage page="odonto" />} />
        <Route exact path={ROUTES.RESID_HIRING_SUCCESS_PAGE} component={() => <ResidReceiptPage />} />
        <Route
          path={ROUTES.REDIRECT_TO_CONTINUE_ASYNC_QUOTATION}
          component={ValidateUserDataToAsyncQuotationFlowPage}
        />
        <Route exact path={ROUTES.ASYNC_QUOTATION_ERROR} component={AsyncQuotationExpiredErrorPage} />
        <Route exact path={ROUTES.CANCEL} component={CancellationPage} />
        <Route exact path={ROUTES.REDIRECT_TO_APP_OR_WEBSITE} component={RedirectToAppOrWebsitePage} />
        <ProtectedRoute exact path={ROUTES.RESID_HIRING_PAGE} component={ResidHiringPage} canActivate />
        <ProtectedRoute
          exact
          path={ROUTES.ODONTO_HIRING_PAGE}
          component={OdontoHiringPage}
          canActivate={userHasQuotation}
        />
        <Route
          exact
          path={ROUTES.AUTH}
          render={() => (
            <RecaptchaProvider>
              <AuthenticationSecondStep />
            </RecaptchaProvider>
          )}
        />
        <Route
          path={ROUTES.CANCEL_DETAIL}
          render={() => (
            <RecaptchaProvider>
              <CancellationDetailPage />
            </RecaptchaProvider>
          )}
        />
        <Route exact path={ROUTES.CANCEL_RECEIPT} component={CancellationReceiptPage} />
        <Route
          exact
          path={ROUTES.CANCEL_TOKEN_CONFIRMATION}
          render={() => (
            <RecaptchaProvider>
              <CancellationTokenConfirmationPage />
            </RecaptchaProvider>
          )}
        />
        <Route exact path={ROUTES.DOWNLOAD_RECEIPT} component={DownloadReceiptPage} />
        {isNotProduction && <Route exact path={ROUTES.ICONS} component={IconsPage} />}
        <Route path={ROUTES.AUTO} component={AutoV2} />
        {ENABLE_AUTH && <Route path={ROUTES.AUTH_V2} component={AuthV2Base} />}
        {ENABLE_FAQ_V2 && <Route exact path={ROUTES.FAQ} component={FAQV2} />}
        {ENABLE_MY_ACCOUNT && <AuthenticatedRoute path={ROUTES.MY_ACCOUNT} component={MyAccountBase} />}
        {<AuthenticatedRoute path={ROUTES.HOSPITAU} component={MyProductsBase} />}
        {<AuthenticatedRoute path={ROUTES.HOSPITAU_PAGE} component={MyProductsBase} />}

        <AllowedChannelsContext.Consumer>
          {({ isPetE2EAllowed, isCarglassAllowed, isBlluAllowed, isConsorcioAllowed, isConectcarAllowed }) => (
            <>
              {isConectcarAllowed ? (
                <Route path={ROUTES.LANDING_CONECTCAR} render={() => <LandingConectcar />} />
              ) : null}
              {isBlluAllowed ? <Route path={ROUTES.LANDING_BLLU} render={() => <LandingBllu />} /> : null}
              {isConsorcioAllowed ? (
                <Route path={ROUTES.LANDING_CONSORCIO} render={() => <LandingConsorcio />} />
              ) : null}
              {isPetE2EAllowed && (
                <>
                  <Route exact path={ROUTES.LANDING_PET} render={() => <LandingPet />} />
                  <Route exact path={ROUTES.PET_SUCCESS_PAGE} component={PetSuccessPage} />
                  <Route exact path={ROUTES.PET_HIRING_PAGE} component={PetHiringPage} />
                  <Route exact path={ROUTES.REALIZA_WELFARE_TERMS} component={RealizaWelfareTermsPage} />
                </>
              )}
              {isCarglassAllowed ? (
                <Route exact path={ROUTES.LANDING_CARGLASS} render={() => <LandingCarglass />} />
              ) : null}
            </>
          )}
        </AllowedChannelsContext.Consumer>
      </Switch>
    </React.Suspense>
  );
}
